<template>
  <div id="contractInfo">
    <Back title="合同详情"></Back>
    <main v-if="contractInfo">
      <van-list>
        <!-- 客户信息 -->
        <section class="block-item">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>客户信息</div>
            <div class="title-right"></div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">客户</span
              ><span>{{ contractInfo.tenantName }}</span>
            </p>
            <p>
              <span class="info-color">企业名称</span
              ><span>{{ contractInfo.companyName }}</span>
            </p>
            <p>
              <span class="info-color">联系人</span
              ><span>{{ contractInfo.tenantName }}</span>
            </p>
            <p>
              <span class="info-color">行业</span
              ><span>{{ contractInfo.industryName }}</span>
            </p>
            <p>
              <span class="info-color">法人</span
              ><span>{{ contractInfo.legalPerson }}</span>
            </p>
            <p>
              <span class="info-color">部门</span
              ><span>{{ contractInfo.deptName }}</span>
            </p>
            <p>
              <span class="info-color">客户经理</span
              ><span>{{ contractInfo.customerName }}</span>
            </p>
            <p>
              <span class="info-color">客户合同编码</span
              ><span>{{ contractInfo.contractCode }}</span>
            </p>
          </div>
        </section>
        <!-- 房源信息 -->
        <section class="block-item">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>房源信息</div>
            <div class="title-right"></div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">园区</span
              ><span>{{ contractInfo.projectName }}</span>
            </p>
            <p>
              <span class="info-color">房源</span>
              <span
                >{{ contractInfo.resourceDtos[0].unitName }}-{{
                  contractInfo.resourceDtos[0].floorName
                }}-{{ contractInfo.resourceDtos[0].resourceName }}</span
              >
            </p>
          </div>
        </section>
        <!-- 基础条款 -->
        <section class="block-item">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>基础条款</div>
            <div class="title-right"></div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">租赁数量</span
              ><span>{{ contractInfo.quantityStr }}</span>
            </p>

            <p>
              <span class="info-color">合同签订时间</span
              ><span>{{
                formatterDate(contractInfo.signDate, "yyyy-MM-dd")
              }}</span>
            </p>

            <p>
              <span class="info-color">合同开始时间</span
              ><span>{{
                formatterDate(contractInfo.effDate, "yyyy-MM-dd")
              }}</span>
            </p>
            <p>
              <span class="info-color">合同结束时间</span
              ><span>{{
                formatterDate(contractInfo.expDate, "yyyy-MM-dd")
              }}</span>
            </p>
            <p>
              <span class="info-color">租房押金</span>
              <span
                >{{ contractInfo.depositUnitPrice
                }}{{ contractInfo.depositUnit == 1 ? "月" : "元" }}</span
              >
            </p>
            <p>
              <span class="info-color">物业押金</span
              ><span
                >{{ contractInfo.propertyDepositUnitPrice
                }}{{
                  contractInfo.propertyDepositUnit == 1 ? "月" : "元"
                }}</span
              >
            </p>
          </div>
        </section>
        <!-- 租赁条款 -->
        <section class="block-item">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>租赁条款</div>
            <div class="title-right"></div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">计费类型</span>
              <span>{{
                zulinList.calcTimeUnit == 1 ? "按天收费" : "按月收费"
              }}</span>
            </p>
            <p>
              <span class="info-color">付款周期</span>
              <span>{{ zulinList.payCycle }}月一付</span>
            </p>
            <p>
              <span class="info-color">基础单价</span>
              <span
                >{{ zulinList.unitPrice }}
                {{ pricingUnitList[zulinList.pricingUnit] }}
              </span>
            </p>
            <p>
              <span class="info-color">提交付款时间</span>
              <span>{{ zulinList.advancePaytime }}天</span>
            </p>
          </div>
        </section>
        <!-- 物业条款 -->
        <section class="block-item">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>物业条款</div>
            <div class="title-right"></div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">计费类型</span>
              <span>{{
                wuyeList.calcTimeUnit == 1 ? "按天收费" : "按月收费"
              }}</span>
            </p>
            <p>
              <span class="info-color">付款周期</span>
              <span>{{ wuyeList.payCycle }}月一付</span>
            </p>
            <p>
              <span class="info-color">基础单价</span>
              <span>
                {{ wuyeList.unitPrice }}
                {{ pricingUnitList[wuyeList.pricingUnit] }}
              </span>
            </p>
            <p>
              <span class="info-color">提交付款时间</span>
              <span>{{ wuyeList.advancePaytime }}天</span>
            </p>
          </div>
        </section>
        <!-- 合同状态 -->
        <section class="block-item">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>合同状态</div>
            <div class="title-right"></div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">合同状态</span>
              <span>{{ contractStatusObj[contractInfo.contractStatus] }}</span>
            </p>
          </div>
        </section>
        <!-- 费用明细 -->
        <!-- <div class="foot-btn">
          <van-button type="info" block>费用明细</van-button>
        </div> -->
      </van-list>
    </main>
    <template v-else> 暂无数据 </template>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getContractInfo } from "@/api/contract/contract";
import { formatterDate } from "@/utils/utils";
import { pricingUnitList, contractStatusObj } from "@/db/contract";

export default {
  components: { Back },
  data() {
    return {
      formatterDate,
      pricingUnitList,
      contractStatusObj,
      contractId: "",
      contractInfo: "",
    };
  },
  created() {
    this.contractId = this.$route.query.contractId;
    this.getContractInfo();
  },
  computed: {
    // 租赁条款
    zulinList() {
      return this.contractInfo.contractClauseVoList.filter(
        (i) => i.exacctId == 1
      )[0];
    },
    // 物业条款
    wuyeList() {
      return this.contractInfo.contractClauseVoList.filter(
        (i) => i.exacctId == 5
      )[0];
    },
  },
  methods: {
    // 【请求】合同详情
    getContractInfo() {
      let data = this.contractId;
      getContractInfo(data).then((res) => {
        if (res) {
          this.contractInfo = res.contractInfoVo;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#contractInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}
// 大盒子边距
.van-list {
  box-sizing: border-box;
  border-left: 0.15rem $bg-color solid;
  border-right: 0.15rem $bg-color solid;
}
// 文本模块
.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  > .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;
    .title-left {
      display: flex;
      align-items: center;
      > i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }
  // 内容区上边距
  .block-content {
    margin-top: 0.1rem;
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.02rem 0;
    }
  }
}
.foot-btn {
  border-top: $border-top;
  border-bottom: $border-bottom;
}
</style>